import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./footer.scss"
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";

const Footer = ({ disclaimer }) => {


    return (
        <>
            <section className="footer-section">
                <div className="footer-wrapper">
                    <div className="footer-content">
                        <span className="footer-social"> 
                            <a href="https://www.facebook.com/freemotorlegal" aria-label="Facebook Page"><FaFacebookF fontSize="2rem"/></a>
                            <a href="https://twitter.com/freemotorlegal" aria-label="Twitter Page"><FaXTwitter fontSize="2rem"/></a>
                        </span>
                        <p>
                            {/* Designed by |  */}
                            Copyright Free Motor Legal {new Date().getFullYear()} |
                            Registered office: 9 Grammar School Yard, Church Lane, Banham, Norfolk, NR16 2HR |
                            Company No: 08087267 | Regulated by the Financial Conduct Authority
                        </p>
                    </div>

                    {/* <div className="footer-disclaimer">
                        <p className="no-margin-bottom"></p>
                    </div> */}

                </div>
            </section>
        </>
    )
}

export default Footer