import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import logo from "../../assets/logo_fml.webp";
import { BsFillTelephoneFill } from "react-icons/bs"
import { GrMail } from "react-icons/gr"
import { FaFacebookF } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io"

import "./nav.scss"



const Nav = ({ }) => {

    //const [openMobileMenu, setOpenMobileMenu] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    const listenScrollEvent = (e) => setIsScrolled(window.scrollY > 0);

    useEffect(() => {

        window.addEventListener("scroll", listenScrollEvent);

    }, [])


    return (
        <div className="sticky-nav">
            <nav className="uk-navbar-container" uk-navbar="true">

                <div className="uk-navbar-left navbar-left">
                    <Link to="/" aria-label="FML Logo">
                        {
                            !isScrolled ? <img src={logo} alt="" className="transition-logo" width="300" />
                                :
                                <img src={logo} className="transition-logo" alt="" width="150" />
                        }
                    </Link>
                </div>

                <div className="uk-navbar-right navbar-right uk-visible@l">
                    <div>
                        <ul className="uk-navbar-nav social-links">
                            <li>
                                <a href="tel:0800-144-4488" className="social-page"><BsFillTelephoneFill /> 0800 144 4488</a>
                            </li>
                            <li>
                                <a href="mailto:enquiry@freemotorlegal.co.uk" className="social-page"><GrMail /> enquiry@freemotorlegal.co.uk </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/freemotorlegal" className="social-page"><FaFacebookF /></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/freemotorlegal" className="social-page"><FaXTwitter /></a>
                            </li>
                        </ul>

                        <ul className="uk-navbar-nav menu">
                            <li>
                                <a href="/" className="menu-link">Home</a>
                            </li>
                            <li>
                                <a href="/sign-up-to-fml" className="menu-link">Sign Up</a>
                            </li>
                            <li>
                                <a href="/how-it-works" className="menu-link">How it works <IoIosArrowDown /></a>
                                <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown={isScrolled ? "offset: 13" : "offset: 44"}>
                                    <div className="submenu-container__display-flex">
                                        <a href="/how-it-works/questions">Questions & Answers</a>
                                        <a href="/how-it-works/terms-conditions">Terms & Conditions</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/about" className="menu-link">About <IoIosArrowDown /></a>
                                <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown={isScrolled ? "offset: 13" : "offset: 44"}>
                                    <div className="submenu-container__display-flex">
                                        <a href="/what-is-motor-legal-protection">What is motor legal protection?</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/report-claims" className="menu-link">REPORT A CLAIM</a>
                            </li>
                            <li>
                                <a href="/contact" className="menu-link">Contact</a>
                            </li>
                            <li>
                                <a href="/news" className="menu-link">News</a>
                            </li>
                        </ul>
                    </div>
                </div>




                <div className="uk-navbar-right uk-hidden@l">
                    <a 
                    href="" 
                    className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #mobile-width-menu"
                    //onClick={() => setOpenMobileMenu(!openMobileMenu)}
                    aria-label="Open Menu"
                    >
                        <span data-uk-navbar-toggle-icon>&nbsp;</span>
                    </a>
                </div>




                <div id="mobile-width-menu" hidden>
                    <div className="uk-navbar">
                        <div className="uk-navbar-dropdown uk-flex uk-flex-column mobile-menu-container">
                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/sign-up-to-fml">Sign Up</a>
                                </li>
                                <li>
                                    <a href="/how-it-works" className="bg_color">How it works</a>
                                    <ul className="submenu">
                                        <li><a href="/how-it-works/questions">Questions & Answers</a></li>
                                        <li><a href="/how-it-works/terms-conditions">Terms & Conditions</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/about" className="bg_color">About</a>
                                    <ul className="submenu">
                                        <li><a href="/what-is-motor-legal-protection">What is motor legal protection?</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="/report-claims">REPORT A CLAIM</a>
                                </li>
                                <li>
                                    <a href="/contact">Contact</a>
                                </li>
                                <li>
                                    <a href="/news">News</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </nav >
        </div >

    )
}

export default Nav